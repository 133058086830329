/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import Sidebar from "../Sidebar/Sidebar";
import SidebarMobile from "../Sidebar/SidebarMobile";

import Map from "../Map/Map";

import { BrowserRouter as Router, Navigate } from "react-router-dom";

import { FLAVOR, INDEX_URL } from "../../helpers/constants";
import { ContextDataProvider } from '../ContextData';
import AxiosSetup from '../../services/AxiosSetup';
import Routing from './Routing';

function MissingRoute(props){
	return <Navigate to={{pathname: INDEX_URL+'404'}} replace />
}

const Main = (props) => {
	
	const setFlavorTheme = () => {
		let bookmarkIcon = require(`../../assets/customer/${process.env.REACT_APP_ASSETS}/meta/bookmark.png`);
		let favIcon = require(`../../assets/customer/${process.env.REACT_APP_ASSETS}/meta/favicon.ico`);
		let link = document.querySelector("link[rel~='icon']");
		let savedIcon = document.querySelector("link[rel~='apple-touch-icon']");

		if (!link) {
			link = document.createElement("link");
			link.rel = "icon";
			document.getElementsByTagName("head")[0].appendChild(link);
		}
		if (!savedIcon) {
			savedIcon = document.createElement("apple-touch-icon");
			savedIcon.rel = "apple-touch-icon";
			document.getElementsByTagName("head")[0].appendChild(savedIcon);
		}
		savedIcon.href = bookmarkIcon;
		link.href = favIcon;
		document.title = `${process.env.REACT_APP_NAME}`;
	}

	useEffect(() => {
		const manifestLink = document.querySelector('link[rel="manifest"]');
		manifestLink.href = `${process.env.PUBLIC_URL}/customer/${process.env.REACT_APP_ASSETS}/manifest.json`;
	  }, []);

	useEffect(() => {
		setFlavorTheme()
	}, [])


	return (
		<Router basename={process.env.REACT_APP_EXTENDED_URL}>
			<ContextDataProvider>
			<AxiosSetup />
			<Routing />
			</ContextDataProvider>
		</Router>
	)
		
}


export default Main;
