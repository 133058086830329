import React, { useState, useEffect } from 'react';

import { Dialog, Card, Collapse } from '@mui/material';
import { images, icons, markers } from "../../helpers/constants";
import { Trans, useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import SafetyZoneMap from '../Map/SafetyZoneMap';
import { downloadPDF, fixDate } from '../../helpers/helperFunctions';
import toast from 'react-hot-toast';


const AlarmCentralModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [centralName, setCentralName] = useState()
    const [errorText, setErrorText] = useState()
    const [selectedAlarmCentral, setSelectedAlarmCentral] = useState(props.data.modelspecificinfo?.alarmcenter?.centerid ? props.data.modelspecificinfo?.alarmcenter?.centerid : -1)
    const [alarmAdditional, setAlarmAdditional] = useState(props.data.modelspecificinfo?.alarmcenter?.additionalinfo ? props.data.modelspecificinfo?.alarmcenter?.additionalinfo : "")
    const [testPage, setTestPage] = useState(1)
    const [testInfo, setTestInfo] = useState(props.testInfo)
    const [positionAccepted, setPositionAccepted] = useState(false)
    const [activeContract, setActiveContract] = useState(false)
    const [acceptTestDone, setAcceptTestDone] = useState(false)
    const [lastTestDate, setLastTestDate] = useState()
    const [loadingTest, setLoadingTest] = useState(false)

    useEffect(() => {
        setShow(props.show)
    },[props.show])

    useEffect(() => {
        if(props.testInfo){
            setTestInfo(props.testInfo ? props.testInfo : null)
        }
        if(props.data){
            setLastTestDate(props.data?.modelspecificinfo?.alarmcenter?.installationtest)
        }
    }, [props])

    const handleClose = () => {
        props.onClose()
        resetData()
      //  setShow(false)
    }

    const resetData = () => {
        setTestPage(1)
        setPositionAccepted(false)
        setActiveContract(false)
        setAcceptTestDone(false)
        setLastTestDate(null)
    }

    const handleSave = (type) => {
        let data = {}
        let reset = false
        switch(type){
            case "central":
                reset = false
                data = {
                    deviceid: props.data.id,
                    alarmcenter: selectedAlarmCentral,
                }
                break;
            case "info_update":
                data = {
                    deviceid: props.data.id,
                    alarmcenter_additionalinfo: alarmAdditional
                }
                break;
            case "unlink_central":
                data = {
                    deviceid: props.data.id,
                    unlink_alarmcenter: true
                }
                setSelectedAlarmCentral(-1)
                break;
            default: data = {}
        }
        props.onSave(data, reset)
    }


    useEffect(() => {
        if(props.type){
            let specificInfo = props.data.modelspecificinfo

        }
    }, [props])

    const handleChange = (type, e) => {
        switch(type){
            case "info":
                setCentralName(e.target.value)
            break;
            default: return
        }
        setErrorText("")
    }

    const sendTestAlarm = async () => {
        setLoadingTest(true)
        const response = await props.sendTestAlarm()
        console.log("IN CORRECT: ", response);
        setLoadingTest(false)
        if(!response.data.error){
            setTestPage(testPage+1)
        }
    }

    const postTest = () => {
        props.postTest()        
    }



    const centralContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.central} alt=""/>
                    <h3 className="ms-2">{t("settings.alarm_central")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="dark-grey text-center pb-3">{t("settings.alarm_central_sub")}</p>
                <div className="d-flex justify-content-betwen">
                    <select onChange={(event) => setSelectedAlarmCentral(event.target.value)} value={selectedAlarmCentral} className="form-control bg-light-grey">
                        <option value="">{t("settings.select")}</option>
                        {props.centrals?.map((central, index) => (
                            <option key={index} value={central.id}>{central.name}</option>
                        ))}
                    </select>
                    {/* <Button onClick={() => downloadCentralData(selectedAlarmCentral)} icon={icons.download} notext={true} btnClass="btn-transparent bg-dark-grey ms-3" /> */}
                </div>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => handleClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave(props.type)} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const infoContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.central} alt=""/>
                    <h3 className="ms-2">{t("settings.additional_info")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
            <p className="dark-grey text-center pb-3">{t("settings.additional_info_sub")}</p>
                {/* <p className="dark-grey text-center pb-3">
                    <Trans
                        i18nKey="settings.alarm_central_sub_selected"
                        components={{
                        central: <span className="bold"/>,
                        }}
                        values={{
                        central: props.data.modelspecificinfo?.alarmcenter?.centername,
                        }}
                    />
                </p> */}
                <textarea rows={3} placeholder={t("settings.additional_info_placeholder")} defaultValue={alarmAdditional} onChange={(event) => setAlarmAdditional(event.target.value)} className="p pb-3 form-control text-area-boy bg-light-grey" />    

            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => handleClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave("info_update")} btnClass="btn-primary">{t("settings.save")}</Button>
            </div>
            </>
        )
    }

    const installationTestContent = () => {
        if(lastTestDate !== null && lastTestDate !== false){
            setLastTestDate(null)
            setTestPage(4)
        }
        
        let testFailed = true
        const stoplight = (val) => {
            let icon = ""
            val === "green"
            ? icon = <img src={icons.stoplight_green} alt=""/>
            : val === "yellow"
            ? icon = <img src={icons.stoplight_yellow} alt=""/>
            : icon = <img src={icons.stoplight_red} alt=""/>
            return icon
        }

      
        if(testInfo?.positionstatus && testInfo?.serverstatus && testInfo?.dataplanstatus){
            testFailed = false
        }

        const positionTime = (status) => {

            if (!status) {
                return (
                    <>
                        {stoplight("red")}
                        <p className="ms-3 dark-grey">{t("settings.position_updated_red")}</p>
                    </>
                );
            }
            return (
                <>
                    {stoplight("green")}
                    <p className="ms-3 dark-grey">{t("settings.position_updated_green")}</p>
                </>
            );
        };

        const serverTime = (status) => {
            if (!status) {
                return (
                    <>
                        {stoplight("red")}
                        <p className="ms-3 dark-grey">{t("settings.server_updated_red")}</p>
                    </>
                );
            }
            return (
                <>
                    {stoplight("green")}
                    <p className="ms-3 dark-grey">{t("settings.server_updated_green")}</p>
                </>
            );
        };

        const dataplanStatus = (status) => {
            if(!status){
                return (
                    <>
                        {stoplight("red")}
                        <p className="ms-3 dark-grey">{t("settings.dataplan_false")}</p>
                    </>
                ); 
            }
            return (
                <>
                    {stoplight("green")}
                    <p className="ms-3 dark-grey">{t("settings.dataplan_true")}</p>
                </>
            );
        }


        const page1 = () => {
            return (
                <>
                    <p className="dark-grey text-center pb-3">{t("settings.installation_test_sub1")}</p>
                    <div className="d-flex mb-3">
                        {positionTime(testInfo?.positionstatus)}
                    </div>
                    <div className="d-flex mb-3">
                        {serverTime(testInfo?.serverstatus)}
                    </div>
                    <div className="d-flex">
                        {dataplanStatus(testInfo?.dataplanstatus)}
                    </div>
                    {!testInfo?.serverstatus && !testInfo?.positionstatus && !testInfo?.dataplanstatus
                        ? <p className="dark-grey text-center py-3">{t("settings.installation_test_error_all")}</p>
                        : !testInfo?.serverstatus || !testInfo?.positionstatus
                        ? <p className="dark-grey text-center py-3">{t("settings.installation_test_error_position")}</p>
                        : !testInfo?.dataplanstatus
                        ? <p className="dark-grey text-center py-3">{t("settings.installation_test_error_dataplan")}</p>
                        :
                        <>
                        <div className="mt-3">
                            <div className="shadow-sm border-12" style={{width: "auto", height: "200px"}}>
                            <SafetyZoneMap device={props.data} noFence={true}/>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <div className={`${positionAccepted ? "bg-checked" : "bg-grey"} size_25 border-sm d-flex justify-content-center p-1`} onClick={() => setPositionAccepted(!positionAccepted)}>
                                {positionAccepted && <img className="white-icon" src={icons.check} alt={""} />}
                            </div>
                            <p className="ms-3 dark-grey">{t("settings.accept_position")}</p>
                        </div>

                        </>
                    }

                </>
            )
        }

        const page2 = () => {
            return(
                <>
                    <p className="dark-grey text-center pb-3">{t("settings.installation_test_p2_sub1")}</p>
                    <div className="d-flex align-items-center mt-3">
                        <div className={`${activeContract ? "bg-checked" : "bg-grey"} size_25 border-sm d-flex justify-content-center p-1`} onClick={() => setActiveContract(!activeContract)}>
                            {activeContract && <img className="white-icon" src={icons.check} alt={""} />}
                        </div>
                        <p className="ms-3 dark-grey">{t("settings.active_contract_sub")}</p>
                    </div>
                </>
            )
        }

        const page3 = () => {
            return(
                <>
                    <p className="dark-grey text-center pb-3">{t("settings.installation_test_p3_sub1")}</p>
                    <div className="d-flex align-items-center mt-3">
                        <div className={`${acceptTestDone ? "bg-checked" : "bg-grey"} size_25 border-sm d-flex justify-content-center p-1`} onClick={() => setAcceptTestDone(!acceptTestDone)}>
                            {acceptTestDone && <img className="white-icon" src={icons.check} alt={""} />}
                        </div>
                        <p className="ms-3 dark-grey">{t("settings.accept_test_done_sub")}</p>
                    </div>
                </>
            )
        }

        const page4 = () => {
            return(
                <>
                    <p className="dark-grey text-center">{t("settings.installation_test_p4_sub", {date: fixDate(props.data?.modelspecificinfo?.alarmcenter?.installationtest, true)})}</p>
                </>
            )
        }


        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.central} alt=""/>
                    <h3 className="ms-2">{t("settings.installation_test")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                {testPage === 1
                    ? page1()
                    : testPage === 2
                    ? page2()
                    : testPage === 3
                    ? page3()
                    : testPage === 4
                    ? page4()
                    : null
                }
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => handleClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               {testPage === 1
                    ? <Button onClick={() => testFailed ? props.onClose() : setTestPage(testPage+1)} btnClass={`btn-primary ${!testFailed && !positionAccepted ? "disabled" : ""}`}>{!testFailed ? t("settings.confirm") : t("settings.start_test")}</Button>
                    : testPage === 2
                    ?  <Button onClick={() => sendTestAlarm()} btnClass={`btn-primary ${!activeContract || loadingTest ? "disabled" : ""}`}><span className={`${loadingTest ? "" : "d-none"} me-2 size_15 spinner-grow`}/>{t("settings.send_test_alarm")}</Button>
                    : testPage === 3
                    ? <Button onClick={() => postTest()} btnClass={`btn-primary ${!acceptTestDone ? "disabled" : ""}`}>{t("settings.confirm")}</Button>
                    : testPage === 4
                    ? <Button onClick={() => setTestPage(1)} btnClass={`btn-primary`}>{t("settings.redo_test")}</Button>
                    : null
                }
              
            </div>
            </>
        )
    }

    const unlinkContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.central} alt=""/>
                    <h3 className="ms-2">{t("settings.unlink_alarm")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="p-3">
                <p className="dark-grey text-center pb-3">{t("settings.unlink_copy")}</p>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => handleClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => handleSave("unlink_central")} btnClass="btn-danger">{t("settings.confirm")}</Button>
            </div>
            </>
        )
    }


    const typeRender = () => {
        switch(props.type){
            case "central": return centralContent()
            case "info": return infoContent()
            case "installation_test": return installationTestContent()
            case "unlink_central": return unlinkContent()
            default: return null
        }
    }


return(
    <>
    <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
        <Card className="settings-dialog d-flex flex-column">
            <div className="w-100 h-100">
                {typeRender()}
            </div>
        </Card>
    </Dialog>
    </>
)
}

export default AlarmCentralModal;