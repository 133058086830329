import React, { useState, useEffect, useCallback } from "react";
import { INDEX_URL, CUST, icons, FLAVOR } from "../helpers/constants";
import AddDevice from "../components/Modals/AddDevice";
import Button from "../components/Buttons/Button";
import Searchbar from "../components/Searchbar/Searchbar";
import * as request from "../services/FetchService";

import { TransitLayerF } from "@react-google-maps/api";
import DeviceRow from "../components/Devices/DeviceRow";
import DeviceRowMobile from "../components/Devices/DeviceRowMobile";
import GroupRow from "../components/Groups/GroupRow";

import CreateGroup from "../components/Modals/CreateGroup";
import { MapTogglesProvider } from "../components/Map/ToggleContext";
import { Card, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";
import { checkToken } from "../helpers/helperFunctions";
import { useContextData } from "../components/ContextData";
import { useIsMobile } from "../helpers/customHooks";
import DeviceSelectionRow from "../components/Devices/DeviceSelectionRow";
import DeviceRowPlaceholder from "../components/Devices/DeviceRowPlaceholder";
import DragComponent from "../components/DragComponent";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

let intervalIdNotMap;

const Devices = (props) => {
	const MOBILE = useIsMobile();
	const { t } = useTranslation();

	const navigate = useNavigate();
	const { deviceListCompact, setDeviceListCompact } = useContextData();
	const [deviceList, setDeviceList] = useState();
	const [groupList, setGroupList] = useState([]);
	const [groupListFull, setGroupListFull] = useState([]);
	const [groupsVisible, setGroupsVisible] = useState(false);
	const [currentFilter, setCurrentFilter] = useState({ type: "last_update", order: false });
	const [showActions, setShowActions] = useState(false);
	const [relayToggle, setRelayToggle] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [relayArray, setRelayArray] = useState([]);
	const [createGroup, setCreateGroup] = useState(false);
	const [errorText, setErrorText] = useState("");
	const [groupName, setGroupName] = useState("");
	const [searchedWord, setSearchedWord] = useState("");
	const [order, setOrder] = useState(true);
	const [loading, setLoading] = useState(true);
	const [initialLoad, setInitialLoad] = useState(false);
	const [currentDevice, setCurrentDevice] = useState(-1);
	const [overrideToggle, setOverrideToggle] = useState(false)
	const [modals, setModals] = useState({
		add_device: false,
		create_group: false,
	});
	const [looping, setLooping] = useState(false);
	const [toggleDrag, setToggleDrag] = useState(false);

	useEffect(() => {
		fetchData();
		return () => {
			setLooping(false);
			stopInterval();
		};
	}, []);

	const fetchData = async () => {
		await checkToken();
		await getDevices();
		await getGroups();
		setLooping(true);
		startInterval();
		await setInitialLoad(true);
	};

	const refreshDevices = () => {
		setInitialLoad(true);
		request.get("devices/compactinfo").then((response) => {
			setDeviceListCompact(response.data.devices);
		});
	};
	const getDevices = async () => {
		console.log(deviceListCompact);
		
		if (deviceListCompact?.length === 0) {
			request.get("devices/compactinfo").then((response) => {
				setDeviceListCompact(response.data.devices);
				
				// setLoading(false)
			});
		} else {
			setDeviceList(deviceListCompact);
			setLoading(false);
		}
	};
	//OLD!!!
	const updateData = useCallback(() => {
		request.get("devices/compactinfo").then((response) => {
			if (!response || !response.data || response.data.error) {
				return;
			}
		
			const newData = response.data.devices;
			const oldData = deviceListCompact ? [...deviceListCompact] : []; // Create a shallow copy
			// Compare newData with oldData and update only the differences
			const updatedData = oldData.map((oldDevice) => {
				const newDevice = newData?.find((newDev) => newDev.id === oldDevice.id);
				if (newDevice) {
					// Compare properties of newDevice with oldDevice and update only the differences
					return {
						...oldDevice,
						...newDevice,
					};
				}
				return oldDevice;
			});
			// Update deviceListMap with the updated data
			setDeviceListCompact(updatedData ? updatedData : []);
		});
	}, [deviceListCompact]);

	//NEW!!!
	// const updateData = useCallback(() => {
	// 	request.get("devices/compactinfo").then((response) => {
	// 		if (!response) {
	// 			return;
	// 		}
	// 		const newData = response.data.devices;
	// 		const oldData = [...deviceListCompact]; // Create a shallow copy

	// 		// Using a map to track old devices for quick access
	// 		const oldDeviceMap = new Map(oldData.map(device => [device.id, device]));
	// 		const updatedData = []; // This will hold the final array of updated and new devices

	// 		// Iterate over new data to update or add devices
	// 		newData.forEach(newDevice => {
	// 			const oldDevice = oldDeviceMap.get(newDevice.id);
	// 			if (oldDevice) {
	// 				// Update old device with new data if it exists
	// 				updatedData.push({
	// 					...oldDevice,
	// 					...newDevice,
	// 				});
	// 				// Remove from map to avoid adding it again later
	// 				oldDeviceMap.delete(newDevice.id);
	// 			} else {
	// 				// Add new device not in the old data
	// 				updatedData.push(newDevice);
	// 			}
	// 		});

	// 		// Add remaining old devices that weren't updated (if any)
	// 		oldDeviceMap.forEach(device => updatedData.push(device));

	// 		// Set the state with the new and updated devices
	// 		setDeviceListCompact(updatedData);
	// 	});
	// }, [deviceListCompact]);

	const startInterval = useCallback(() => {
		setLooping(true);
		intervalIdNotMap = setInterval(updateData, 10000);
	}, [updateData]);

	const stopInterval = useCallback(() => {
		setLooping(false);
		clearInterval(intervalIdNotMap);
	}, []);

	useEffect(() => {
		if (looping) {
			startInterval();
		} else {
			stopInterval();
		}
		return () => {
			stopInterval(); // Cleanup function to stop interval when unmounting
		};
	}, [looping, startInterval, stopInterval]);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.hidden) {
				stopInterval(); // Pause interval when window is in background
			} else {
				startInterval(); // Resume interval when window is in foreground
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			stopInterval(); // Cleanup function to stop interval when unmounting
		};
	}, [startInterval, stopInterval]);

	useEffect(() => {
		if (initialLoad) {
			const sorted = sortList(deviceListCompact, currentFilter);
			setDeviceList(sorted ? sorted : []);
			setInitialLoad(false);
		}
	}, [deviceListCompact]);

	useEffect(() => {
		if (deviceList) {
			setLoading(false);
		}
	}, [deviceList]);

	useEffect(() => {
		if (deviceList !== deviceListCompact && !loading) {
			setDeviceList(deviceListCompact);
		}
		if (searchedWord !== "") {
			filterSearch(searchedWord);
		}
	}, [deviceListCompact, loading]);

	useEffect(() => {
		if (loading) {
			return;
		}
		if (allSelected) {
			let updatedDevices = [...deviceList];
			updatedDevices.map((device, index) => {
				device.selected = true;
			});
			setDeviceList(updatedDevices);
		} else {
			let updatedDevices = [...deviceList];
			updatedDevices.map((device, index) => {
				device.selected = false;
			});
			setDeviceList(updatedDevices);
		}
	}, [allSelected, createGroup]);

	const selectDevice = (index) => {
		if (loading) {
			return;
		}
		let updatedDevices = [...deviceList];
		if (index !== -1) {
			var device = updatedDevices[index];
			if (device.selected !== true) {
				device.selected = true;
			} else {
				device.selected = false;
			}
			updatedDevices[index] = device;
			setDeviceList(updatedDevices);
		}
	};

	const closeActions = () => {
		setRelayToggle(false);
		setShowActions(false);
		setOverrideToggle(false)
		setRelayArray([]);
	};
	const toggleModal = (modalName) => {
		setModals((prevState) => ({
			...prevState,
			[modalName]: !prevState[modalName],
		}));
	};

	const getGroups = () => {
		request.get("device/group/list").then((response) => {
			if (response.data) {
				setGroupListFull(response.data);
				setGroupList(response.data);
			}
		});
	};

	const filterSearch = (searchWord) => {
		setSearchedWord(searchWord);
		let array;
		let match;
		if (groupsVisible && !createGroup) {
			if (!searchWord || searchWord === "") {
				setGroupList(groupListFull);
				setSearchedWord("");
				return;
			}
			array = groupListFull;
			match = array.filter((group) => {
				const item = searchWord.toLowerCase();
				return group.name.toLowerCase().indexOf(item) > -1;
			});
			setGroupList(match);
		} else {
			if (!searchWord || searchWord === "") {
				if (deviceListCompact.length === 0) {
					return;
				}
				const sortedDevices = sortList(deviceListCompact, currentFilter);
				setSearchedWord("");
				setDeviceList(sortedDevices);
				return;
			}
			array = deviceListCompact;
			match = array?.filter((device) => {
				const item = searchWord.toLowerCase();
				return device.name.toLowerCase().indexOf(item) > -1 || device.uniqueid.toLowerCase().indexOf(item) > -1;
			});
			const sortedDevices = sortList(match, currentFilter);
			setDeviceList(sortedDevices);
		}
	};
	const sortList = (list, filter) => {
		if (!list || list.length === 0) {
			return;
		}
		return list.sort((a, b) => {
			if (filter.type === "name") {
				// Compare the names
				if (a.name < b.name) return filter.order ? -1 : 1;
				if (a.name > b.name) return filter.order ? 1 : -1;
				return 0;
			} else if (filter.type === "status") {
				if (a.status < b.status) return filter.order ? -1 : 1;
				if (a.status > b.status) return filter.order ? 1 : -1;
			} else if (filter.type === "last_update") {
				// Handle null values differently based on sort order
				if (a.lastupdate === null && b.lastupdate !== null) return filter.order ? -1 : 1;
				if (a.lastupdate !== null && b.lastupdate === null) return filter.order ? 1 : -1;

				// Convert string dates to Date objects for comparison
				let dateA = a.lastupdate ? new Date(a.lastupdate) : 0;
				let dateB = b.lastupdate ? new Date(b.lastupdate) : 0;

				if (dateA < dateB) return filter.order ? -1 : 1;
				if (dateA > dateB) return filter.order ? 1 : -1;
				return 0;
			} else if (filter.type === "group") {
				if (a.groupname === null && b.groupname !== null) return filter.order ? -1 : 1;
				if (a.groupname !== null && b.groupname === null) return filter.order ? 1 : -1;
				// Compare the groupname
				if (a.groupname < b.groupname) return filter.order ? -1 : 1;
				if (a.groupname > b.groupname) return filter.order ? 1 : -1;
				return 0;
			}
			// Implement other case comparisons if needed
			return 0;
		});
	};

	const filterOrder = (filter) => {
		let filter2 = { type: filter, order: order };
		setOrder(!order);
		// let filter2 = { type: filter, order: order }
		setCurrentFilter(filter2);
		const sortedDevices = sortList(deviceList, filter2);
		setDeviceList(sortedDevices);
	};

	const checkChecked = () => {
		let checkedDevices = deviceList.filter((device) => device.selected).map((device) => device.id);
		return checkedDevices;
	};

	const handleChange = (field, e) => {
		setErrorText("");
		if (field === "name") {
			setGroupName(e.target.value);
		}
	};

	useEffect(() => {
		setAllSelected(false);
		if (!createGroup) {
			setGroupName("");
			setErrorText("");
		}
	}, [createGroup]);

	const saveGroup = () => {
		let data = {
			name: groupName,
			deviceid: checkChecked(),
		};
		request.post("", "device/group/create", data).then((response) => {
			if (response.data.successful) {
				fetchData();
				setCreateGroup(false);
				toast.success(t("toast.success"));
			} else {
				toast.error(t("toast.error"));
			}
		});
	};

	const updateGroup = (obj) => {
		request.post("", "device/group/update", obj).then((response) => {
			if (response.data.successful) {
				fetchData();
				toast.success(t("toast.success"));
			} else {
				toast.error(t("toast.error"));
			}
		});
	};

	const deleteGroup = (id) => {
		let data = {
			groupid: id,
		};
		request.post("", "device/group/delete", data).then((response) => {
			if (response.data.successful) {
				fetchData();
			}
		});
	};

	const renderCard = () => (
		<Card className={`py-0 px-1 mt-1 mb-2`}>
			<div className="d-flex flex-row justify-content-between align-items-center px-1 pt-3 pb-2 border-bottom">
				<h4 className="mb-2">{t("devices.actions")}</h4>
				<img className="mb-2 size_25 cursor-pointer close_img" src={icons.close_gray} onClick={() => setShowActions(!showActions)} alt="..." />
			</div>
			<ul className="list-group list-group-flush">
				<li
					className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
					onClick={() => {
						setRelayToggle(!relayToggle);
						setOverrideToggle(false)
					}}
				>
					<p className={`my-2 ${relayToggle ? "primary bold" : "dark_grey"}`}>{t("devices.enable_disable_power")}</p>
				</li>
				{FLAVOR === "alco" &&
					<li
						className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
						onClick={() => {
							setOverrideToggle(!overrideToggle);
							setRelayToggle(false)
						}}
					>
						<p className={`my-2 ${overrideToggle ? "primary bold" : "dark_grey"}`}>{t("devices.override_alco")}</p>
					</li>
				}

			</ul>
		</Card>
	);

	const deviceRowHeader = () => {
		if (MOBILE) {
			return (
				<div className="row m-0 me-1 ps-3">
					<div className="col-4 pe-1 m-0 p-0" onClick={() => filterOrder("name")}>
						<p className="bold">{t("devices.name")}</p>
					</div>
					<div className="p-0 m-0 col-8">
						<div className="row d-flex justify-content-around m-0 p-0">
							<div className="col-7 m-0 p-0 text-truncate" onClick={() => filterOrder("last_update")}>
								<p className="bold">{t("devices.last_active")}</p>
							</div>
							<div className="col-3 m-0 p-0">
								<p className="bold">{t("devices.battery")}</p>
							</div>
							<div className="col-2 m-0 p-0" />
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="row m-0 me-1 ps-3">
					<div className="col-4 m-0 p-0 cursor-pointer" onClick={() => filterOrder("name")}>
						<p className="bold primary">{t("devices.name")}</p>
					</div>
					<div className="p-0 m-0 col-8">
						<div className="row d-flex justify-content-around m-0 p-0">
							<div className="col-5 m-0 p-0 text-truncate cursor-pointer" onClick={() => filterOrder("last_update")}>
								<p className="bold primary">{t("devices.last_active")}</p>
							</div>
							<div className={`col-3 m-0 ps-3 p-0 cursor-pointer ${FLAVOR === "bike" ? "opacity-0 disabled" : ""}`} onClick={() => filterOrder("group")}>
								<p className="bold primary">{t("devices.group")}</p>
							</div>
							<div className="col-2 m-0 p-0">
								<p className="bold primary">{t("devices.battery")}</p>
							</div>
							<div className="col-1 m-0 p-0" />
						</div>
					</div>
				</div>
			);
		}
	};

	useEffect(() => {
		if (loading) {
			return;
		}
		if (!relayToggle) {
			let updatedDevices = [...deviceList];
			updatedDevices.map((device) => {
				device.selected = false;
			});
			setDeviceList(updatedDevices);
		}
	}, [relayToggle]);

	return (
		<>
			<div className="section-left_bottom_data" id="resizable-element">
				{/* TITLE */}
				{/* TITLE */}
				<DragComponent dragId="resizable-element" initialH="60px" maxH={80} minH="60px" close={toggleDrag} setClose={() => setToggleDrag(false)} />
				<div className="d-flex flex-row mt-4 align-items-center page_header">
					<img src={icons.sidebar.device_active} className="title_icon primary-icon" alt="..." />
					<h1 className="h1 primary ms-3 bold">{t("devices.my_devices")}</h1>
				</div>
				{/* Add device */}
				<Button className="my-3" onClick={() => (groupsVisible ? setCreateGroup(!createGroup) : toggleModal("add_device"))} btnClass={createGroup ? "btn-primary" : "btn-left-section"} icon={icons.plus}>
					<span className="uppercase">{groupsVisible ? t("devices.create_group") : t("devices.add")}</span>
				</Button>

				{/* Search & Groups */}
				{!createGroup && (
					<div className="d-flex flex-row">
						<Searchbar sort={false} submitSearch={(data) => filterSearch(data)} updateOrder={(data) => filterOrder(data)} />
						<Button btnClass={groupsVisible ? "btn-selected" : "btn-left-section"} className={`ms-3 ${FLAVOR === "bike" ? "opacity-0 disabled" : ""}`} icon={icons.group} onClick={() => setGroupsVisible(!groupsVisible)}>
							<span className="uppercase">{t("devices.groups")}</span>
						</Button>
					</div>
				)}
				{!groupsVisible ? (
					<>
						{/* Actions */}
						<div className="position-relative my-3 d-flex">
							<div className={`me-3 align-self-center ${!relayToggle && !overrideToggle ? "d-none" : deviceList && !deviceList.some((device) => !device.selected) ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`} onClick={() => setAllSelected(!allSelected)}>
								{deviceList && !deviceList.some((device) => !device.selected) && <img className="white-icon" src={icons.check} alt={""} />}
							</div>
							<Button btnClass="btn-left-section" className={`${FLAVOR === "bike" ? "opacity-0 disabled" : ""}`} onClick={() => setShowActions(!showActions)} icon={icons.plus}>
								{t("devices.actions")}
							</Button>
							<Collapse in={showActions} className="position-absolute top-100 w-50 byrger">
								{renderCard()}
							</Collapse>
						</div>
						{loading ? (
							<div className="device_list shadow-small">
								<DeviceRowPlaceholder />
							</div>
						) : deviceList?.length === 0 ? (
							<>
								<h3 className="bold dark-grey text-center mt-5">{t("devices.no_devices")}</h3>
							</>
						) : (
							<>
								{deviceRowHeader()}
								<div className={`device_list ${relayToggle ? "pushed" : ""} scrollable-y shadow-small`}>
									{deviceList?.map((device, index) => {
										return MOBILE ? (
											<DeviceRowMobile
												device={device}
												index={index}
												key={index}
												currentDevice={currentDevice}
												setCurrentDevice={(data) => setCurrentDevice(data)}
												refresh={() => refreshDevices()}
												deviceListLength={deviceList.length}
												checkboxVisibility={relayToggle}
												focusOnMap={() => setToggleDrag(true)}
												setDeviceToggle={(data) => selectDevice(index)}
											/>
										) : (
											<DeviceRow
												device={device}
												index={index}
												key={index}
												currentDevice={currentDevice}
												setCurrentDevice={(data) => setCurrentDevice(data)}
												refresh={() => refreshDevices()}
												deviceListLength={deviceList.length}
												checkboxVisibility={relayToggle || overrideToggle}
												setDeviceToggle={(data) => selectDevice(index)}
											/>
										);
									})}
								</div>
							</>
						)}
						<div className={`d-flex justify-content-end mt-3 ${!relayToggle && "d-none"}`}>
							<Button btnClass="btn-secondary me-3" onClick={() => closeActions()}>
								{t("devices.cancel")}
							</Button>
							<Button btnClass="btn-primary" onClick={() => console.log(checkChecked())}>
								{t("devices.toggle_relay")}
							</Button>
						</div>
						<div className={`d-flex justify-content-end mt-3 ${!overrideToggle && "d-none"}`}>
							<Button btnClass="btn-secondary me-3" onClick={() => closeActions()}>
								{t("devices.cancel")}
							</Button>
							<Button btnClass="btn-primary" onClick={() => console.log(checkChecked())}>
								{t("devices.toggle_override")}
							</Button>
						</div>
					</>
				) : createGroup ? (
					<>
						<div className="d-flex flex-column">
							<h3 className="">{t("devices.create_group")}</h3>
							<div className="d-flex flex-row flex-fill my-3">
								<div className="d-flex flex-column w-50">
									<h4 className="primary">{t("geofence.name")}</h4>
									<form>
										<div className="form-group d-flex align-items-center">
											<input
												type="text"
												className="form-control bg-grey"
												id="name"
												value={groupName}
												onChange={(e) => {
													handleChange("name", e);
												}}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														e.preventDefault(); // Prevent the default Enter key behavior
														saveGroup();
													}
												}}
											/>
											<div className="invalid-feedback ms-1 bottom">{errorText === "" ? "" : errorText}</div>
										</div>
									</form>
								</div>
							</div>
							<div className="d-flex flex-column">
								<h4 className="primary mb-2">{t("devices.choose_device")}</h4>
								<Searchbar className="mb-3" submitSearch={(data) => filterSearch(data)} />
								<div className={`group_device_list scrollable-y shadow ${!deviceList.some((device) => device.selected) ? "" : "pushed"}`}>
								<DeviceSelectionRow
									text={t("devices.choose_all")}
									index={-1}
									selected={deviceList.filter(device => device.groupid === null).every(device => device.selected)}
									onClick={() => {
										const allSelected = deviceList.filter(device => device.groupid === null).every(device => device.selected);
										setAllSelected(!allSelected);
									}}
								/>		
									{deviceList.map((device, index) => {
										if (device.groupid == null) {
											return (
												<DeviceSelectionRow
													key={index}
													index={index}
													text={device.name}
													selected={device.selected}
													onClick={() => selectDevice(index)}
												/>
											);
										}
										return null;
									})}
								</div>
								<div className={`d-flex justify-content-end mt-3 ${!deviceList?.some((device) => device.selected) && "d-none"}`}>
									<Button btnClass="btn-secondary me-3" onClick={() => setCreateGroup(false)}>
										{t("devices.cancel")}
									</Button>
									<Button btnClass="btn-primary" onClick={() => saveGroup()}>
										{t("devices.save")}
									</Button>
								</div>
							</div>
						</div>
					</>
				) : groupList && groupList.length > 0 ? (
					<div className="mt-3 group_scroll scrollable-y shadow-small">
						{groupList.map((group, index) => (
							<GroupRow group={group} devices={deviceList} editGroup={(data) => updateGroup(data)} deleteGroup={(data) => deleteGroup(data)} index={index} key={index} />
						))}
					</div>
				) : (
					(!groupList || groupList.length === 0) && <h3 className="primary regular text-center mt-5">{t("devices.no_groups")}</h3>
				)}
			</div>

			<AddDevice show={modals.add_device} onClose={() => toggleModal("add_device")} updateDevices={() => refreshDevices()} />
			<CreateGroup show={modals.create_group} onClose={() => toggleModal("create_group")} />
		</>
	);
};

export default Devices;
